.page-navigation {
    border-top: 1px solid rgb(189, 186, 186);
    padding-top: 1px;
    align-items: center;
    ul {
        margin: 0;
    }

    .page-link {
        border: 0;

        &:focus {
            box-shadow: none;
            outline: 0;
        }

        &:hover {
            color: #fff;
            background-color: #007bff;
            border: 0;
        }
    }

    .page-item.active .page-link {
    }


}