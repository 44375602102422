.title-page-detail {
    margin-inline: 4px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
}
.text-normal {
    font-size: 16px;
}
.text-16-14 {
    font-size: 16px;
}
.text-normal-m {
    font-size: 20px;
}
.custom-row {
    .card {
        padding: 12px;
    }
}
.div-radio {
    display: flex;
    justify-content: space-between;
}

@media (min-width: 1401px) {
    .big-btn {
        height: 46px !important;
        flex-shrink: 0 !important;
        border-radius: 10px;
        background: var(--standart-theme-primary, #1976d2) !important;
        text-transform: uppercase !important;
        border: none !important;
        font-size: 14px !important;
    }
}

@media screen and (max-width: 1400px) {
    .icon-border {
        width: 35px;
        height: 35px;
    }
    .table-fixed .text-content {
        font-size: 12px;
    }
    .card-service .form-control {
        font-size: 12px !important;
    }
    .size-mini-12 {
        font-size: 12px !important;
        padding: 2px;
    }
    .size-mini-14 {
        font-size: 14px !important;
        padding: 2px;
    }
    .title-page-detail {
        font-size: 18px !important;
    }
    .text-16-14 {
        font-size: 14px;
    }
    .text-normal {
        font-size: 12px !important;
    }
    .text-normal-m {
        font-size: 18px !important;
    }
    .big-btn {
        height: 46px !important;
    }
    .input-search-group {
        width: 235px !important;
    }
    .table-product {
        .card-body {
            padding: 0px;
        }
        thead tr {
            background-color: #f5f5f5;
        }
        th {
            // color: red;
            font-size: 12px !important;
        }
        td {
            font-size: 12px !important;
        }

        .img-avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
        }
        .css-ql19fo-MuiDataGrid-overlayWrapper {
            position: static;
        }
    }
    .MuiTabPanel-root {
        padding: 0px !important;
    }
    .custom-row {
        .form-label,
        .form-modal-input,
        .input-group-text {
            font-size: 14px;
            margin-bottom: 0.25rem;
        }
        .form-control,
        .input-group-text {
            padding: 0.275rem 0.75rem;
        }
        .dropdown-toggle {
            padding: 2px 6px;
        }
        .card {
            padding: 6px 10px 0px 10px;
        }
        .div-radio {
            font-size: 14px;
        }
        .form-cus {
            margin-bottom: 0.75rem !important;
        }
    }
    .table-custom {
        th {
            // color: red;
            font-size: 12px;
        }
        td {
            font-size: 12px;
            padding: 2px 6px !important;
        }
    }
    .sz-16 {
        font-size: 14px !important;
    }
    .input-search-group {
        padding: 4px 16px !important;
    }
    .row {
        padding: 2px !important;
    }
    .row > * {
        padding: 2px 4px !important;
    }

    .div-card-white {
        padding: 4px !important;
    }
    .status-custom,
    .status-info,
    .status-danger,
    .status-success,
    .status-warning,
    .status-primary {
        font-size: 12px !important;
    }
}
