#page-dashboard {
    padding-top: 10px;
}
:root {
  --sidebar-width: 225px;
}

#main-content {
    transition: 300ms ease-in-out;
    min-height: calc(100vh - 50px);
    margin-top: 50px;
    background: #fff;
    overflow-x: auto;
    // padding: 0 10px 0 10px !important;
}

.me-5 {
    margin-bottom: 20px;
}

.table-header {
    color: #757575;
    font-weight: 600;
}

.div-price {
    width: 160px;
    display: flex;
    justify-content: right;
}

.table-fixed {
    .text-content {
        word-break: break-word;
        max-width: 420px;
    }
}

.display_number {
    width: 100px;
    display: flex;
    justify-content: flex-end;
}
Button.cancel {
    background-color: #f5f5f5;
    border: none;
    color: rgba(0, 0, 0, 0.87);
}
Button[type="submit"] {
    min-width: 125px;
}

.table-scroll {
    max-height: 250px;
    overflow-x: auto;
}
.MuiSelect-select {
    border: none;
}

.page-content {
    overflow-x: hidden;
    padding: 0px 10px 0 10px;
    background-color: white;
    min-height: calc(100vh - 100px);
}
.header-page {
    display: flex;
    flex-direction: column;
    margin-inline: 8px;
    gap: 4px;
    margin-bottom: 4px;
}

.div-pointer {
    cursor: pointer;

    &:hover {
        // border: 0.5px solid #71ade9;
        background-color: #c6d1dd !important;
    }
}
.title-page-content {
    // margin: 2px 20px 2px 5px ;
    display: flex;
    justify-content: space-between;
    // align-items: center;
    // p {
    //     font-size: 20px;
    //     font-weight: 600;
    // }
}
.div-step {
    display: flex;
    justify-content: flex-end;
}
.css-n7tliy-MuiStep-root {
    min-width: max-content;
}
.cont-step {
    min-width: 70%;
    max-width: 90%;
}
.step-des {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.input-no-border {
    border-left: none;
}

.input-no-border-1 {
    border-right: none;
    max-width: 300px;
}

.input-no-border-2 {
    border-left: none;
    border-right: none;
}

.pointer-btn {
    cursor: pointer;
    margin: 0 2px;
}
.btn-cur {
    cursor: pointer;
}
.btn-inline {
    width: max-content;
}
.hover-btn {
    cursor: pointer;
    &:hover {
        color: #1976d2 !important;
    }
}

.btn-status-order {
    min-width: 100px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-style: normal;
    gap: 4px !important;
    border-radius: 18px !important;
    // border: none !important;
    padding: 2px !important;
}
.item-license {
    font-size: 16px;
    font-weight: 600;
}
.table-custom {
    flex: 1;
    .card-body {
        padding: 0px;
    }
    thead tr {
        background-color: #f5f5f5;
    }
    th {
        // color: red;
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }

    .img-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #dbdbdb;
        border: 1px solid #dbdbdb;
    }
    .css-ql19fo-MuiDataGrid-overlayWrapper {
        position: static;
    }
    .overline {
        font-weight: 600 !important;
    }
}

.table-product {
    .card-body {
        padding: 0px;
    }
    thead tr {
        background-color: #f5f5f5;
    }
    th {
        // color: red;
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }

    .img-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .css-ql19fo-MuiDataGrid-overlayWrapper {
        position: static;
    }
}

.div-date-search {
    // display: flex;
    .form-label {
        white-space: nowrap;
        margin-right: 3px;
        margin-top: 5px;
    }
    .react-datepicker-wrapper {
        margin: 0px 3px;
        // width: 100%;
    }
}
.title-page-list {
    margin-bottom: 2px;
    margin-top: 8px;
    font-size: 24px !important;
    display: flex;
    font-weight: 700;
    line-height: 32px;
    justify-content: space-between;
}
.form-search {
    // .react-datepicker__month-container {
    //     z-index: 9999;

    // }
    .input-group-text,
    .form-control {
        // border: none ;
        // border-radius: 0;
        // border-bottom: 1px solid ;
        // padding-left: 4px;
    }
    .form-select {
        // border: none ;
        // border-radius: 0;
        // border-bottom: 1px solid ;
        // padding-left: 4px;
    }
    input,
    input::placeholder {
        font-size: 14px;
    }
    select,
    input::placeholder {
        font-size: 14px;
    }

    input:focus {
        -webkit-box-shadow: none;
        border-bottom: 2px solid #003481;
    }
}

.btn-border {
    margin-top: 5px;
    width: 242px;
    border: none !important;
    height: 44px;
    background: #145ea8 !important;
    border-radius: 36px;
    &:hover {
        filter: brightness(90%);
    }
}
.btn-border-white {
    margin-top: 5px;
    width: 242px;
    height: 44px;
    color: #1976d2;
    font-weight: 600;
    border-radius: 36px;
    background-color: white;
    &:hover {
        background-color: #70b5f9;
        color: rgb(51, 51, 241);
    }
}

.btn-mini {
    padding: 1px;
}
.title_lable {
    color: var(--standart-theme-primary, #1976d2);
    font-feature-settings: "liga" off;
    /* body-1/body-1, bold */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.08px;
}
// Page
.detail-order {
    background-color: #f9f9f9;

    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
        color: #1976d2;
        font-size: 26px;
    }
    .css-1hv8oq8-MuiStepLabel-label.Mui-completed {
        font-weight: 600;
        font-size: 12px;
        color: #1976d2;
    }
    .css-1hv8oq8-MuiStepLabel-label {
        font-size: 12px;
    }
    input,
    input::placeholder {
        font-size: 14px;
    }
    .form-control .input {
        width: 100%;
        border: none;
        border-bottom: 1px solid;
        border-radius: revert;
    }

    .card-order {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid #bdbdbd;
        min-height: 600px;
    }

    .card-user {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid #bdbdbd;
        min-height: 100px;
    }

    .card-service {
        // max-height: calc(100vh - 190px);
        margin-bottom: 8px;
        input,
        input::placeholder {
            font-size: 16px;
        }
        .form-control {
            width: 100%;
            // border: none;
            // border-bottom: 1px solid;
            border-radius: 4px;
            padding: 3px;
        }
        .div-price {
            .form-control {
                width: 80px;
                // border: none;
                // border-bottom: 1px solid;
                border-radius: 4px;
            }
        }
    }
    .div-bill {
        // height: 200px;
        background-color: white;
        .form-control {
            // border: none;
            // border-bottom: 1px solid;
            // border-radius: revert;
            // border-radius: 4px;
        }
        input,
        input::placeholder {
            font-size: 16px;
            text-align: right;
            padding: 2px;
        }
        input {
            font-weight: 600;
        }
    }

    .card-money {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid #bdbdbd;
        .form-control {
            // border: none;
            // border-bottom: 1px solid;
            border-radius: revert;
        }
        input,
        input::placeholder {
            font-size: 16px;
            text-align: right;
            // padding: 0;
        }
        input {
            font-weight: 600;
        }
    }

    .card-date {
        padding: 12px;
        border-radius: 12px;
        border: 1px solid #bdbdbd;
        input,
        input::placeholder {
            font-size: 16px;
        }
        input {
            font-weight: 600;
            text-align: right;
        }
        .form-control {
            border: none;
            border-bottom: 1px solid;
            border-radius: revert;
        }
    }
}
// page

.text_blue {
    font-weight: 600;
    color: #1976d2;
}

.text_italic {
    font-size: 12px;
    font-style: italic;
}

.text_btn_primary {
    text-decoration: none !important;
    color: #1976d2;
    cursor: pointer;
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    &:hover {
        font-weight: 600;
    }
}

.lable-input {
    font-weight: 600;
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .image-wrapper {
        width: 96px;
        height: 96px;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        border: 1px dashed #d9d9d9;
        position: relative;
    }

    .btn-view,
    .btn-delete {
        border-radius: 2px;
        font-size: 0.865rem;
        font-weight: 700;
        background-color: white;
        border: none;
        padding: 5px 10px;
        margin: 0 5px;
        cursor: pointer;
    }

    .btn-view {
        color: #1976d2;
    }

    .btn-delete {
        color: red;
    }
    .image-overlay {
        position: absolute;
        border-radius: 5px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(230, 230, 230, 0.5);
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .image-wrapper:hover .image-overlay {
        opacity: 1;
    }
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .image-wrapper {
        width: 96px;
        height: 96px;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        border: 1px dashed #d9d9d9;
        position: relative;
    }

    .btn-view,
    .btn-delete {
        border-radius: 2px;
        font-size: 0.865rem;
        font-weight: 700;
        background-color: white;
        border: none;
        padding: 5px 10px;
        margin: 0 5px;
        cursor: pointer;
    }

    .btn-view {
        color: #1976d2;
    }

    .btn-delete {
        color: red;
    }
    .image-overlay {
        position: absolute;
        border-radius: 5px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(230, 230, 230, 0.5);
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .image-wrapper:hover .image-overlay {
        opacity: 1;
    }
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .image-wrapper {
        width: 96px;
        height: 96px;
        background-size: cover;
        background-position: center;
        border-radius: 5px;
        border: 1px dashed #D9D9D9;
        position: relative;
    }

    .btn-view,
    .btn-delete {
        border-radius: 2px;
        font-size: 0.865rem;
        font-weight: 700;
        background-color: white;
        border: none;
        padding: 5px 10px;
        margin: 0 5px;
        cursor: pointer;
    }

    .btn-view {
        color: #1976d2;
    }

    .btn-delete {
        color: red;
    }
    .image-overlay {
        position: absolute;
        border-radius: 5px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(230, 230, 230, 0.5);
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .image-wrapper:hover .image-overlay {
        opacity: 1;
    }
}


.img-product-item {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    position: relative;
}
.img-service-item {
    background-color: black;
    color: white;
    font-weight: bold;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progress_bar {
    position: relative;
    overflow: hidden;
    width: 80% !important;
    height: 15px;
    border-radius: 10px;
    background-color: #d9d9d9;
}

#demo-select-small {
    padding: 2px 5px;
    border: none;
    font-size: 14px;
}
.color-warning {
    background-color: #fdb762;
    border: none;
}
.color-warning2 {
    background-color: #ffebb8;
    border: none;
}
.color-success {
    background-color: #36b37e;
}
.btn-select-status {
    border-radius: 18px !important;
    padding: 2px !important;
}
.item-0 {
    border-left: 3px solid rgb(255, 0, 0) !important;
}
.item-1 {
    border-left: 3px solid rgb(255, 115, 0) !important;
}
.item-2 {
    border-left: 3px solid #0dcaf0 !important;
}
.item-3 {
    border-left: 3px solid #92ecc2 !important;
}
.item-4 {
    border-left: 3px solid #0d6efd !important;
}
.bg-success-2 {
    background-color: #92ecc2;
}
.modal-order-print {
    // width: 100%;
    margin: 0 8px;
    .fix-container-order {
        .div-image-logo {
            width: 130px;
            height: 120px;

            .image-logo-print {
                width: 120px;
                height: 120px;
            }
        }

        .tittle-header {
            font-size: 16px;
            font-weight: 600;
        }

        .text-info-order {
            font-size: 12px;
        }
        .text-time-note {
            text-align: end;
            font-weight: 600;
            font-style: italic;
            font-size: 14px;
        }

        .div-table {
            width: 100%;
            border-collapse: collapse;
            font-size: 12px;

            .line-bold {
                // line-height: 2px;
                font-weight: 600;
            }
        }

        .div-table tr {
            // line-height: 8px;
        }

        .tittle-order {
            font-size: 20px;
            font-weight: 600;
        }

        .code-order {
            font-size: 16px;
            font-weight: 600;
            font-style: italic;
        }

        .table {
            max-width: 790px;
            font-size: 12px;
        }

        .table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        .th,
        td {
            padding: 4px;
        }

        .td {
            padding-left: 2px;
        }

        .tr {
            min-height: 24px !important;
        }
    }
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding: 20px;
    }
    a[href]:after {
        content: none !important;
    }

    .modal-order-print {
        .div-image-logo {
            width: 200px;
            height: 200px;
            .image-logo-print {
                width: 200px;
                height: 200px;
            }
        }
        .tittle-header {
            font-size: 20px;
            font-weight: 600;
        }
        .text-bold-16 {
            font-size: 16;
            font-weight: 600;
        }
        .div-table {
            width: 96%;
            border-collapse: collapse;
        }
        .table {
            max-width: 790px;
        }
        .table,
        th,
        td {
            border: 1px solid black;
            border-collapse: collapse;
        }

        .td {
            padding-left: 3px;
        }
        .tr {
            min-height: 32px !important;
        }
        .footer-bill {
            page-break-inside: avoid;
        }
        .body-bill {
            page-break-inside: avoid;
        }
        .uncut {
            page-break-inside: avoid;
        }
    }
}

.table-report {
    width: 100%;
    height: calc(100vh - 220px);
    overflow: auto;
    font-size: 14px;
    .td {
        font-size: 14px;
    }
    .fix-container {
        height: 100%;
        // overflow-y: scroll;

        .div-image-logo {
            width: 130px;
            height: 120px;

            .image-logo-print {
                width: 120px;
                height: 120px;
            }
        }

        .tittle-header {
            font-size: 14px;
            font-weight: 600;
        }

        .text-info {
            font-size: 14px;
        }

        .div-table {
            width: 100%;
            border-collapse: collapse;
            // font-size: 14px !important;

            .line-bold {
                font-weight: 600;
            }
        }

        .tittle-order {
            font-size: 20px;
            font-family: auto;
            font-weight: 600;
        }

        .code-order {
            font-size: 16px;
            font-family: auto;
            font-weight: 600;
            font-style: italic;
        }

        .table {
            max-width: 790px;
            font-size: 12px;
        }

        tr {
            border: 1px solid #eeeeee;
            border-collapse: collapse;
            // font-family: ui-serif;
            // line-height: 30px;
        }

        .td {
            padding-left: 2px;
        }

        .tr {
            min-height: 24px !important;
        }
    }
}

// .none-border{
//     tr{
//         border:none !important
//     }
// }

.react-datepicker-wrapper {
    width: 100%;
}
.text-btn-outline {
    font-size: 14px;
    color: #1976d2;
    cursor: pointer;
}
.text-bold-14 {
    font-size: 14px;
    font-weight: 600;
}
.text-bold-16 {
    font-size: 16px;
    font-weight: 600;
}
.text-bold-12 {
    font-size: 12px;
    font-weight: 600;
}
.sz-16 {
    font-size: 16px !important;
}
.sz-14 {
    font-size: 14px;
}
.sz-12 {
    font-size: 12px;
}
.sz-22 {
    font-size: 22px !important;
}
.title-size-16 {
    font-size: 16px;
    font-weight: 600;
}
.border-25 {
    border-radius: 25px;
}
.card-3 {
    padding: 16px 24px 24px 24px !important;
    border-radius: 0 !important;
    background-color: white;
    border: none !important;
    box-shadow: 0px 2px 4px rgba(168, 168, 168, 0.25);
}
.div-card {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #cfcece;
    margin: 4px 0px;
    background-color: white;
}
.div-card2 {
    padding: 2px 4px;
    border-radius: 2px;
    border: 1px solid #e4e4e4;
    margin: 2px 0px;
    background-color: white;
}

.div-card-circle {
    padding: 6px 12px;
    border-radius: 35px;
    border: 1px solid #bdbdbd;
    background-color: #f4f4f4;
    margin: 4px 0px;
    text-align: center;
}

.div-card-white {
    padding: 12px;
    border-radius: 12px;
    margin: 4px 0px;
    background-color: white;
}

.item-option {
    padding: 5px 2px !important;
    // background-color: red;
    cursor: pointer;
}
.list_chip {
    display: flex;
    flex-wrap: wrap;
    .item-chip {
        padding: 3px 4px;
        margin: 2px;
        background-color: rgb(199, 235, 241);
        border-radius: 12px;

        border: 1px solid #bdbdbd;
    }
}

.wd-20 {
    width: 20%;
}
.wd-60 {
    width: 620%;
}
.btn-add {
    width: max-content;
}
.text-user-info {
    // background-color: lightgray;
    // padding: 10px;
    // font-size: 16px;
    color: #1976d2;
    cursor: pointer;
}
.modal-custom-style {
    input::placeholder {
        color: #c6c6c6 !important;
    }
}

.grid-container-print {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(4, 1fr);
    box-sizing: border-box;

    .item {
        border: 1px solid #000;
        margin: 0 0 -1px -1px;
    }
}

.table-service {
    max-height: 450px !important;
}

.card-service {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid #bdbdbd;
    margin-bottom: 8px;

    input,
    input::placeholder {
        // font-size: 16px;
    }

    .form-control {
        width: 100%;
        // border: none;
        // border-bottom: 1px solid;
        border-radius: 4px;
        padding: 3px;
        text-align: right;
    }

    .div-price {
        .form-control {
            width: 80px;
            // border: none;
            // border-bottom: 1px solid;
            border-radius: 4px;
        }
    }

    .div-card {
        // border: #ececec 1px solid;
        min-height: 120px;
        border-radius: 8px;
        width: 96%;
        padding: 14px 6px;
        box-shadow:
            0px 2px 1px -1px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
}

// .row {
//     max-height: none;
//     padding: 10px 0;
//     .muitiple-row {
//         max-height: none !important;
//     }
// }

.div-plate {
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 4px;
    padding: 2px 4px;
    min-width: 100px;
    margin: auto;
    font-weight: 600;
    letter-spacing: 1.25px;
    padding: 4px 8px;
}
.div-plate-table {
    border-radius: 5px;
    border: 1px solid var(--opacity-text-disabled, rgba(0, 0, 0, 0.37));
    // padding: 2px 4px;
    min-width: 68px;
    font-weight: 600;
    text-align: center;
}

.number-danger {
    color: red;
    font-weight: 600;
}
.input-select-order {
    width: 280px !important;
}
.div-tab {
    .css-1ujnqem-MuiTabs-root {
        min-height: 32px !important;
        color: red !important;
        font-size: 12px !important;
        .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
            min-height: 32px !important;
            padding: 4px;
        }
    }
}
.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.css-13xfq8m-MuiTabPanel-root {
    padding: 0px 8px !important;
}
.css-1869usk-MuiFormControl-root {
    min-width: 100px !important;
}
.cont-customer {
    text-align: left;
    .text-name {
        color: #4b4b4b;
        font-size: 15px;
        font-weight: 600;
    }
}
.page_detail {
    padding: 24px;
}

.div-table-grid {
    width: 100%;
    height: calc(100vh - 220px);
    // max-height: calc(100vh - 220px);
}

.div-receive {
    .item-button {
        border-radius: 5px;
        background: #fafafa;
        text-align: center;
        height: 80px;
        padding-top: 10px;
        cursor: pointer;
    }
    .active-item {
        border: 1px solid #1976d2;
        color: #1976d2;
        background-color: rgba(181, 218, 255, 0.05);
    }
}
.div-service {
    padding: 30px;
    border-radius: 20px !important;
    line-height: 35px;
}
.div-service:hover {
    border: 2px solid #1976d2;
    transform: scale(1.01);
}

.div-filter {
    gap: .25rem;
    display: flex;
    justify-content: space-between;
    .input-group {
        max-width: 300px;
        margin: 0 5px;
        .input-no-border-1 {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
        .icon-input {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
    .filter-select-date {
        font-size: 14px !important;
        border-radius: 6px;
        background-color: white;
        color: black;
        align-items: center;
    }
    .filter-select {
        font-size: 14px !important;
        border-radius: 6px;
        background-color: white;
        align-items: center;
        max-width: 300px;
        color: black;
        .dropdown-menu.show {
            display: block;
            overflow-y: scroll;
            max-height: 300px;
        }
    }
    .search-time {
        background-color: white !important;
    }
    .div-date-search {
        // display: flex;
        padding: 3px 8px;

        .dropdown-menu {
        }
        .form-label {
            white-space: nowrap;
            margin-right: 3px;
            margin-top: 5px;
        }
        .react-datepicker-wrapper {
            margin: 0px 3px;
            // width: 100%;
        }
        .form-control {
            width: none;
        }
    }
    .dropdown-toggle {
        padding: 4px 18px;
        border-color: #e0e0e0;
    }
}

.div-infor-disable {
    border-radius: 5px;
    background-color: rgba(181, 218, 255, 0.05);
    color: black;
    border: 1px solid #1976d2;
    padding: 4px;
    .icon {
        width: 40px;
        height: 40px;
        text-align: center;
    }
    .title {
        font-weight: 600;
    }
}

.disable-item {
    color: grey;
}

.danger-item {
    color: red;
}
.btn-submit-order {
    width: 100%;
    padding: 16px;
}
.btn-export {
    display: inline-flex !important;
    align-items: center;
    gap: 5px;
    border-radius: 4px !important;
    background-color: #2280de !important;
}
.btn-export:hover {
    background-color: #145697 !important;
}

@font-face {
    font-family: "Roboto";
    src: url(../../public/static/fonts/Roboto-Regular.ttf) format("truetype");
}
//  search
body {
    font-family: "Roboto", sans-serif !important;
}

@media (min-width: 992px) {
    .modal-image {
        max-width: 750px !important;
    }
    .main-image {
        background-color: #f5f5f5 !important;
    }
    .item-image {
        background-color: red !important;
    }
}
.big-img {
    height: calc(100vh - 300px);
    aspect-ratio: 1/1;
    object-fit: cover;
}

.all-img {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
}

.img-item {
    width: calc(33.33% - 8px);
    margin-bottom: 8px;
}

.small-img {
    width: 90px;
    object-fit: cover;
    aspect-ratio: 1 / 1;

    &.selected {
        border: 2px solid red;
    }
}

.icon-border {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 10px;
    border: none;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    flex-shrink: 0;
    border-radius: 12px;
    background: rgba(25, 118, 210, 0.05) !important;

    &:hover {
        filter: brightness(90%);
    }
}
.custom-datepicker-process-bar {
    width: 100%;
    border: none;
    border-radius: 5px;
    background: #e3f2fd;
    font-size: 13px !important;

    .react-datepicker__time-container {
        order: 0;
    }
    .react-datepicker__time {
        flex: none;
    }
    .react-datepicker {
        display: flex;
        flex-direction: row;
    }
    .react-datepicker__month-container {
        order: 1;
    }
}

.icon-border-mini {
    width: 20px;
    height: 20px;
    border: none;
    padding: 3px !important;
    background: #fff;
}
.ant-input {
    border: none !important;
}
.ant-select-selector {
    border-radius: 6px !important;
}
.input-search-group {
    display: flex !important;
    width: 335px !important;
    padding: 7px 16px 7px 16px !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 6px !important;
    border: 1px solid var(--grey-grey-lighten-2, #e0e0e0) !important;
    background: white !important;

    .input-search {
        background-color: inherit !important;
        flex: 1 0 0 !important;
        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 20px !important;
        letter-spacing: 0.25px !important;
        border: none !important;
        outline: none !important;
    }
}

.source-input {
    border-radius: 4px;
    border: 1px solid var(--grey-grey-lighten-3, #eee);
    display: flex;
    width: 331px;
    align-items: center;
    gap: 4px;
}

.source-add-btn {
    border: none !important;
    height: 100% !important;
    width: 50px !important;
    background: var(--standart-theme-primary, #1976d2) !important;
    box-sizing: content-box !important;
    padding: 0 !important;
}

.big-btn {
    flex-shrink: 0 !important;
    border-radius: 10px;
    background: var(--standart-theme-primary, #1976d2) !important;
    text-transform: uppercase !important;
    border: none !important;
    font-size: 14px !important;
}

.big-btn:hover {
    filter: brightness(110%) !important;
}
.add-new-info {
    display: flex;
    // width: 331px;
    padding: 12px 4px !important;
    gap: 4px !important;
    border-radius: 5px !important;
    background: #f3f8fd !important;
    color: #1976d2 !important;
    border: #9ab7d3 solid 1px !important;
    // border: none !important;
}

.ptn {
    min-width: 87px;
    font-size: 12px !important;
    padding: 4px 12px !important;
    gap: 4px !important;
    border-radius: 47px !important;
    color: white !important;
    font-weight: 400 !important;
    border-radius: 47px !important;
    color: white !important;
    border: none !important;
    font-style: normal;
    text-align: center;
    &:hover { 
        filter: brightness(0.8);
    }
    // Trang thai phieu tiep nhan
    &.cho-duyet {
        background: #fb8c00 !important;
        color: var(--shades-white, #fff) !important;
    }

    &.tiep-nhan {
        background: var(--light-blue-light-blue-lighten-2, #4fc3f7) !important;
        color: var(--shades-white, #fff) !important;
    }

    &.bao-gia {
        background: var(--yellow-yellow-lighten-4, #fff9c4) !important;
        color: var(--opacity-text--primary, rgba(0, 0, 0, 0.87)) !important;
        border: 1px solid #f9eb25 !important;
    }

    &.sua-chua {
        background: var(--yellow-yellow-lighten-4, #f9a825) !important;
        color: var(--shades-white, #fff) !important;
    }

    &.btn-complete {
        background: var(--blue-blue-accent-4, #2962ff) !important;
        color: var(--shades-white, #fff) !important;
    }

    &.xuat-xuong {
        background: var(--light-green-light-green-darken-1, #7cb342) !important;
        color: var(--shades-white, #fff) !important;
    }

    &.cancel {
        background: var(--red-red, #f44336) !important;
        color: var(--shades-white, #fff) !important;
    }
}

.btn-status {
    text-decoration: none;
    font-size: 12px !important;
    padding: 4px 12px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    border-radius: 47px !important;
    color: white !important;
    font-weight: 400 !important;
    border: none;
    font-style: normal;
    &.lock {
        background: var(--standart-theme-error, #ff5252) !important;
    }
    &.active {
        background: var(--standart-theme-success, #4caf50) !important;
    }
    &.info {
        background: var(--light-blue-light-blue-lighten-2, #4fc3f7) !important;
    }
}

.btn-back {
    border-radius: 50px;
    width: max-content;
    background-color: transparent;
    flex-direction: row;
    padding: 5px;
    color: #1976d2 !important;
    border: 2px solid #1976d2 !important;

    &:hover {
        background-color: #1976d2 !important;
        color: #ffffff !important;
    }
}

.red {
    background-color: #ff5252 !important;
    color: #ffffff !important;
}

.orange {
    background-color: #ff7f00 !important;
    color: #ffffff !important;
}
.text-orange {
    color: #ff8400 !important;
}
.text-blue {
    color: #1976d2 !important;
}
.text-overflow {
    display: block !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

.yellow {
    background-color: #f3f363 !important;
    color: #000000 !important;
}

.green {
    background-color: #4caf50 !important;
    color: #ffffff !important;
}

.blue {
    background-color: #0b5ed7 !important;
    color: #ffffff !important;
}

.indigo {
    background-color: #4b0082 !important;
    color: #ffffff !important;
}

.violet {
    background-color: #9654b0 !important;
    color: #ffffff !important;
}

.pink {
    background-color: #ff69b4 !important;
    color: #000000 !important;
}

.black {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.white {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.gray {
    background-color: #808080 !important;
    color: #ffffff !important;
}

.brown {
    background-color: #a52a2a !important;
    color: #ffffff !important;
}

.red-text {
    color: #ff0000;
}

.blue-text {
    color: #0000ff;
}

.green-text {
    color: #00ff00;
}

.yellow-text {
    color: #ffff00;
}

.white-text {
    color: #ffffff;
}

.black-text {
    color: #000000;
}

.gray-text {
    color: #808080;
}

.orange-text {
    color: #ffa500;
}

.pink-text {
    color: #ff69b4;
}

.purple-text {
    color: #800080;
}

.brown-text {
    color: #a52a2a;
}

.navy-text {
    color: #000080;
}

// Typography helper classes
h1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 96px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 96px !important;
    letter-spacing: -1.44px !important;
}

h2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 60px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 60px !important;
    letter-spacing: -0.3px !important;
}

h3 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 48px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 50px !important;
}

h4 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 34px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 40px !important;
    letter-spacing: 0.085px !important;
}

h5 {
    margin: 0 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 22px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 32px !important;

    &.medium {
        font-weight: 600 !important;
    }
}

h6 {
    color: rgba(0, 0, 0, 0.87);
    font-family: Roboto !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0.05px !important;
}

.subtitle-1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.024px !important;
}

.subtitle-2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    letter-spacing: 0.014px !important;
}

.body-1 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-feature-settings: "liga" off !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0.08px !important;
}

.body-2 {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.035px !important;
}

.button {
    color: rgba(0, 0, 0, 0.6) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.caption {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.048px !important;
}

.overline {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 32px !important;
    letter-spacing: 0.24px !important;
    text-transform: uppercase !important;
}

.font-italic {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: italic !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.thin {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 100 !important;
    line-height: 24px !important;
}

.light {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
}

.regular {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.medium {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.bold {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 24px !important;
}

.black {
    color: rgba(0, 0, 0, 0.87) !important;
    font-family: Roboto !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 900 !important;
    line-height: 24px !important;
}

.text--primary {
    color: var(--opacity-text--primary, rgba(0, 0, 0, 0.87)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.text--secondary {
    color: var(--opacity-text--secondary, rgba(0, 0, 0, 0.6)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.text--disabled {
    color: var(--opacity-text--disabled, rgba(0, 0, 0, 0.37)) !important;
    font-family: Roboto !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
.css-19kzrtu {
    padding: 4px !important;
}
.css-1nmdiq5-menu,
.css-14h4o58-menu {
    z-index: 9999 !important;
}

.MuiDataGrid-panel {
    inset: unset !important;
    right: 0 !important;
    transform: translate3d(-15px, 259px, 0px) !important;
}
.MuiDataGrid-toolbarContainer {
    justify-content: flex-end !important;
    margin-right: 20px;
}

.color-warn {
    color: #fd7200;
}

.status-primary {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #1976d2;
    color: #1976d2;
    background-color: #f1f1ff;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}

.status-warning {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #ffc107;
    background-color: #ffffff;
    color: #ff9307;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}
.status-success {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #198754;
    background-color: #f0faf6;
    color: #198754;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}

.status-danger {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid red;
    color: red;
    background-color: #fdf5f5;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}

.status-info {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #0ccced;
    color: #0bc8f3;
    background-color: #f5fefd;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}

.status-custom {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #9c27b0;
    color: #9c27b0;
    background-color: #f3e5f5;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}
.status-default {
    align-items: center;
    justify-content: center;
    display: inline-block;
    border: 1px solid #1976d2;
    color: #1976d2;
    background-color: #f1f1ff;
    border-radius: 14px;
    padding: 2px 6px;
    min-width: 80px;
    width: fit-content;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

#navbar-detail {
    display: flex;
    justify-content: space-between;
    background-color: #FFF !important;
    margin-bottom: 0;
    transition: 300ms ease-in-out;
    margin-inline: var(--sidebar-width) !important;
    height: 50px;
    z-index: 10;
    left: 0;
    padding-inline-end: var(--sidebar-width) !important;
    box-shadow: 2px 2px 2px #888888;
    position: fixed;
    width: 100%;
    top: 0
}

.top-header {
    justify-content: space-between;
    margin: 0 10px 0 10px;
}

.danger-btn {
    text-decoration: none !important;
    cursor: pointer;
    padding: 6px 14px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 3px !important;
    background-color: rgb(255, 77, 77) !important;
    border: 1px solid transparent !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.outline-danger-btn {
    text-decoration: none !important;
    cursor: pointer;
    padding: 6px 14px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 3px !important;
    background-color: inherit !important;
    border: 1px solid rgb(255, 77, 77) !important;
    color: rgb(255, 77, 77) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.outline-danger-btn:hover {
    background-color: rgb(255, 238, 238) !important;
}

.text-btn {
    cursor: pointer;
    padding: 0px 15px !important;
    text-decoration: none !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 4px !important;
    display: flex !important;
    border-radius: 3px !important;
    background-color: inherit !important;
    border: none !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.text-btn:hover {
    background-color: #FFFFFF !important;
    color: #1976d2 !important;
}
.primary-btn {
    padding: 6px 14px !important;
    cursor: pointer;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-decoration: none !important;
    border-radius: 3px !important;
    border: 1px solid transparent !important;
    color: white !important;
    background: var(--standart-theme-primary, #1976d2) !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.primary-btn:hover {
    filter: brightness(110%) !important;
}
.outline-primary-btn {
    cursor: pointer;
    text-decoration: none !important;
    padding: 6px 14px !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 3px !important;
    background-color: inherit !important;
    border: 1px solid #1976d2 !important;
    color: #1976d2 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.outline-primary-btn:hover {
    background-color: rgb(238, 246, 255) !important;
}

.default-btn {
    padding: 6px 14px !important;
    cursor: pointer;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-decoration: none !important;
    border-radius: 3px !important;
    border: 1px solid transparent !important;
    color: black !important;
    background: #dddee0 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
        
    &:hover {
        filter: brightness(90%);
    }
}
.outline-default-btn {
    cursor: pointer;
    text-decoration: none !important;
    padding: 6px 14px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 3px !important;
    background-color: inherit !important;
    border: 1px solid #a3a8af !important;
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        "Open Sans",
        "Helvetica Neue",
        sans-serif !important;
}
.outline-default-btn:hover {
    background-color: rgb(230, 233, 235) !important;
}

.summary-row {
    font-weight: 600 !important;
    font-size: 0.85rem !important;
}
.ant-table-summary {
    z-index: 0 !important;
}
.ant-table-wrapper .ant-table-column-title {
    z-index: 0 !important;
}

.ant-btn,.text-btn {
    border: 1px solid #bdbbbb !important;
}

.ant-picker .ant-select {
    * {
        all: unset;
        font-size: 14px !important;
        color: black !important;
        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
    }
}
.ant-select-selector {
    * {
        all: unset;
        font-size: 14px !important;
        color: black !important;
        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif !important;
    }
}

.cus-row {
    max-height: 3rem !important;
}
.table-analysis {
    .ant-table-cell {
        padding: 4px 6px !important;
    }
    height: 100%;
    .table-row-light {
        background-color: #ffffff !important;
        > td {
            background-color: #ffffff !important;
        }
    }
    .table-row-dark {
        background-color: #fafafa !important;
        > td {
            background-color: #fafafa !important;
        }
    }
}
.ant-table-wrapper {
    border: 1px solid silver !important;
}
