/**
|--------------------------------------------------
| @vunv
| https://enqtran.com - enqtran@gmail.com
|--------------------------------------------------
*/

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto",
        "Helvetica Neue",
        Arial,
        sans-serif;
    background-color: #f5f5f5;
}

#wrapper {
    position: relative;
    top: 0;
    min-height: 100vh;

    .form-control:focus,
    button:focus {
        box-shadow: none !important;
        outline: none !important;
    }

    .media-object {
        margin-right: 10px;
    }

    .media {
        margin-bottom: 10px;
        margin-top: 10px;
        // display: block;
    }

    .media-right {
        margin-left: 10px;
    }
}